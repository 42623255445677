import React from 'react';
import { config } from '@config';

/**
 * Defines expected props for this component
 */
interface IFooter {}

/*
|--------------------------------------------------------------------------
| START STYLED COMPONENTS
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| END STYLED COMPONENTS
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| START REACT
|--------------------------------------------------------------------------
*/
/**
 * The site footer
 */
export const Footer = (props: IFooter) => {
	return (
		<footer className='footer widget-footer clearfix'>
			<div className='third-footer'>
				<div className='container'>
					<div className='row'>
						<div className='col-xs-12 col-sm-12 col-md-12 col-lg-4 widget-area'>
							<div className='widget widget_text clearfix'>
								<div className='footer-logo'>
									<a href='/'>
										<img
											id='footer-logo-img'
											className='img-center'
											src='images/logo.svg'
											alt='SaniShield Logo'
										/>
									</a>
								</div>
							</div>
						</div>
						<div className='widget-area col-xs-12 col-sm-12 col-md-3 col-lg-4'>
							<aside className='widget widget-text'>
								<div className='featured-icon-box icon-align-before-content'>
									<a href='/contact' className='footer-contact-link'>
										<div className='featured-icon'>
											<div className='ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-sm'>
												<i className='flaticon-email' />
											</div>
										</div>
										<div className='featured-content'>
											<div className='featured-title'>
												<h5 id='footer-contact'>Contact Us</h5>
											</div>
										</div>
									</a>
								</div>
							</aside>
						</div>
					</div>
				</div>
			</div>
			<div className='bottom-footer-text'>
				<div className='container'>
					<div className='row copyright'>
						<div className='col-sm-9'>
							<span>Copyright © 2020 SaniShield</span>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};
/*
|--------------------------------------------------------------------------
| END REACT
|--------------------------------------------------------------------------
*/
