/** Determine the app stage */
const stage = process.env.NODE_ENV === 'production' ? 'prod' : 'dev';

/** App configuration */
export const config = {
	/** The stage the app is running in */
	stage,
	/** Whether we are currently running in the browser (is window defined?) */
	isBrowser: typeof window !== 'undefined',
	/** The configuration settings for the website (used by gatsby and others) */
	website: {
		/** Contact email */
		email: 'support@sanishield.com',
		/** The title of the website */
		title: 'SaniShield',
		/** The short title used for the app manifest (Mobile Home Screen) */
		shortTitle: '',
		/** The alternate title of the website */
		altTitle: '',
		/** The organization name used for SEO schema */
		publisher: '',
		/** The base description of the website */
		description: 'SaniShield ',
		/** The base URL of the website (Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/) */
		url: 'https://sanishield.com',
		/** The twitter handle for the website (for twitter cards) */
		twitter: '',
		/** Background color (in offline manifest) */
		backgroundColor: '#3368C6',
		/** Theme color (in offline manifest) */
		themeColor: '#3368C6',
		/** Copyright text */
		copyright: '© 2020 by SaniShield',
	},
	/** Details on the api */
	api: {
		/** The api endpoint */
		endpoint:
			stage === 'prod'
				? 'https://9qmjcphy7c.execute-api.us-east-1.amazonaws.com/prod/'
				: 'https://0atrml0pn5.execute-api.us-east-1.amazonaws.com/dev/',
	},
};
