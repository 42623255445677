import React from 'react';

/**
 * Defines expected props for this component
 */
interface IBackToTop {}

/*
|--------------------------------------------------------------------------
| START STYLED COMPONENTS
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| END STYLED COMPONENTS
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| START REACT
|--------------------------------------------------------------------------
*/
/**
 * The button that allows the user to go back to the top of the page
 */
export const BackToTop = (props: IBackToTop) => {
	return (
		<a id='totop' href='#top'>
			<i className='fa fa-angle-up' />
		</a>
	);
};
/*
|--------------------------------------------------------------------------
| END REACT
|--------------------------------------------------------------------------
*/
