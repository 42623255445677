/** @jsx jsx */
import { jsx } from 'theme-ui';

/**
 * Defines expected props for this component
 */
interface IHeader {
	/** The route that is currently active */
	activeRoute?: string;
}

/*
|--------------------------------------------------------------------------
| START REACT
|--------------------------------------------------------------------------
*/
/**
 * The site header
 */
export const Header = ({ activeRoute, ...props }: IHeader) => {
	return (
		<header id='masthead' className='header ttm-header-style-01'>
			{/* site-header-menu */}
			<div id='site-header-menu' className='site-header-menu ttm-bgcolor-white'>
				<div className='site-header-menu-inner ttm-stickable-header'>
					<div className='container'>
						<div className='row'>
							<div className='col'>
								{/*site-navigation */}
								<div className='site-navigation d-flex flex-row'>
									{/* site-branding */}
									<div className='site-branding'>
										<a className='home-link' href='/' title='SaniShield' rel='home'>
											<img
												id='logo-img'
												className='img-center logo'
												src='images/logo.svg'
												alt='SaniShield Logo'
											/>
										</a>
									</div>
									{/* site-branding end */}
									<div className='btn-show-menu-mobile menubar menubar--squeeze'>
										<span className='menubar-box'>
											<span className='menubar-inner' />
										</span>
									</div>
									{/* menu */}
									<nav className='main-menu menu-mobile' id='menu'>
										<ul className='menu'>
											<li className={`mega-menu-item ${activeRoute === 'contact' ? 'active' : ''}`}>
												<a href='/contact'>Contact</a>
											</li>
										</ul>
									</nav>
									{/* site-navigation end*/}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* site-header-menu end*/}
		</header>
	);
};
/*
|--------------------------------------------------------------------------
| END REACT
|--------------------------------------------------------------------------
*/
