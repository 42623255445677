import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { config } from '@config';

/**
 * Defines expected props for this component
 */
interface IProps {
	/** Post data from CMS */
	postNode?: any;
	/** The web page's slug (pathname) - DO NOT include prefix or end with a slash */
	slug: string;
	/** The title of the page (SEO) */
	title: string;
	/** The meta description of the page (SEO) */
	description?: string;
	/** Whether this page should be noindexed / nofollowed */
	noindex?: boolean;
	/** Whether this should use the SEO schema for a POST type */
	postSEO?: boolean;
	/** Whether this should use the SEO schema for a PAGE type */
	pageSEO?: boolean;
}

// TODO: Figure out best way to use this component and comment it

/**
 * Injects SEO data onto page
 */
export const SEO = ({ postNode, slug, postSEO, pageSEO, title, description, noindex }: IProps) => {
	let finalTitle;
	let finalDescription;
	let image;
	let imgWidth;
	let imgHeight;

	// Generate the URL of the page we're on (used for SEO purposes)
	const pageUrl = `${config.website.url}${slug !== 'index' ? `/${slug}/` : ''}`;

	// Set Default OpenGraph Parameters for Fallback
	finalTitle = config.website.title;
	finalDescription = config.website.description;

	if (title) {
		finalTitle = title;
	}

	if (description) {
		finalDescription = description;
	}

	// TODO: Figure out what this was being used for
	// // Replace with Page Parameters if post or page
	// if (postSEO || pageSEO) {
	// 	title = postNode.title;
	// 	description =
	// 		postNode.metaDescription === null
	// 			? postNode.body.childMarkdownRemark.excerpt
	// 			: postNode.metaDescription.internal.content;

	// 	pageUrl = `${config.website.url}/${slug}/`;
	// }
	// // Use Hero Image for OpenGraph
	// if (postSEO) {
	// 	image = `https:${postNode.heroImage.ogimg.src}`;
	// 	imgWidth = postNode.heroImage.ogimg.width;
	// 	imgHeight = postNode.heroImage.ogimg.height;
	// }

	// Default Website Schema
	const schemaOrgJSONLD = [
		{
			'@context': 'http://schema.org',
			'@type': 'WebSite',
			'url': config.website.url,
			'name': config.website.title,
			'alternateName': config.website.altTitle ?? '',
		},
	] as any;

	// Blog Post Schema
	// TODO: Make sure schema is appropriate (and consider adding new schemas)
	if (postSEO) {
		schemaOrgJSONLD.push(
			{
				'@context': 'http://schema.org',
				'@type': 'BreadcrumbList',
				'itemListElement': [
					{
						'@type': 'ListItem',
						'position': 1,
						'item': {
							'@id': config.website.url,
							'name': config.website.title,
						},
					},
					{
						'@type': 'ListItem',
						'position': 2,
						'item': {
							'@id': pageUrl,
							'name': title,
						},
					},
				],
			},
			{
				'@context': 'http://schema.org',
				'@type': 'BlogPosting',
				'url': pageUrl,
				'name': title,
				'alternateName': config.website.altTitle ?? '',
				'headline': title,
				'image': {
					'@type': 'ImageObject',
					'url': image,
					'width': imgWidth,
					'height': imgHeight,
				},
				'author': {
					'@type': 'Person',
					'name': '',
					'url': '',
				},
				'publisher': {
					'@type': 'Organization',
					'name': config.website.publisher,
					'url': config.website.url,
				},
				'datePublished': postNode.publishDateISO,
				'mainEntityOfPage': pageUrl,
			}
		);
	}

	// Page SEO Schema
	if (pageSEO) {
		schemaOrgJSONLD.push({
			'@context': 'http://schema.org',
			'@type': 'WebPage',
			'url': pageUrl,
			'name': title,
		});
	}

	return (
		<Helmet>
			{/* No Index non production websites */}
			{(noindex || config.stage !== 'prod') && <meta name='robots' content='noindex, nofollow' />}
			{/* Add canonical tags (in case duplicate pages ever arise) */}
			<link rel='canonical' href={pageUrl} />

			{/* General tags */}
			<title>{finalTitle}</title>
			<meta name='image' content={image} />
			<meta name='description' content={finalDescription} />

			{/* Schema.org tags */}
			<script type='application/ld+json'>{JSON.stringify(schemaOrgJSONLD)}</script>

			{/* OpenGraph tags */}
			<meta property='og:title' content={finalTitle} />
			{postSEO ? <meta property='og:type' content='article' /> : null}
			<meta property='og:url' content={pageUrl} />
			<meta property='og:image' content={image} />
			<meta property='og:image:width' content={imgWidth} />
			<meta property='og:image:height' content={imgHeight} />
			<meta property='og:description' content={finalDescription} />

			{/* Twitter Card tags */}
			<meta name='twitter:card' content='summary_large_image' />
			<meta name='twitter:creator' content={config.website.twitter ?? ''} />
			<meta name='twitter:title' content={finalTitle} />
			<meta name='twitter:image' content={image} />
			<meta name='twitter:description' content={finalDescription} />
		</Helmet>
	);
};
