/** @jsx jsx */
import { jsx } from 'theme-ui';
import loadingGif from '../assets/images/loading.gif';

/**
 * Defines expected props for this component
 */
interface ILoading {}

/*
|--------------------------------------------------------------------------
| START COMPONENTS
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| END COMPONENTS
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| START MAIN
|--------------------------------------------------------------------------
*/
/**
 * A loading gif
 */
export const Loading = (props: ILoading) => {
	return (
		<img src={loadingGif} alt='Loading' sx={{ zIndex: 100, width: 30, height: 30 }} {...props} />
	);
};
/*
|--------------------------------------------------------------------------
| END MAIN
|--------------------------------------------------------------------------
*/
