import React from 'react';
import { useScript } from '@hooks';
import { withPrefix } from 'gatsby';

/**
 * Defines expected props for this component
 */
interface ILayout {
	/** The children to render in the layout */
	children: React.ReactNode;
}

/*
|--------------------------------------------------------------------------
| START STYLED COMPONENTS
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| END STYLED COMPONENTS
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| START REACT
|--------------------------------------------------------------------------
*/
/**
 * The basic layout wrapper of the website
 */
export const Layout = (props: ILayout) => {
	useScript('/revolution/js/slider.js');
	useScript('/js/main.js');

	return <div className='page'>{props.children}</div>;
};
/*
|--------------------------------------------------------------------------
| END REACT
|--------------------------------------------------------------------------
*/
