/** @jsx jsx */
import { jsx, Text as TUIText, TextProps } from 'theme-ui';

/**
 * Defines the expected props for this component
 */
export interface IText extends TextProps {
	/** Fix this later with the right ref */
	ref?: any;
	/** Whether the truncate the text with ellipsis */
	truncate?: boolean;
}

/*
|--------------------------------------------------------------------------
| START MAIN
|--------------------------------------------------------------------------
*/
/**
 * Basic text element
 */
export const Text = ({ truncate, ...props }: IText) => {
	return (
		<TUIText
			sx={{
				lineHeight: 'standard',
				// If truncate is provided, add additional styles to truncate text
				...(truncate && {
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
				}),
			}}
			{...props}
		/>
	);
};
/*
|--------------------------------------------------------------------------
| END MAIN
|--------------------------------------------------------------------------
*/
